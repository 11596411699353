<template>
    <div
        class="tooltip-wrapper"
        @mouseover="showTooltip(this)"
        @mouseleave="hideTooltip(this)"
    >
        <i class="fas fa-info-circle" />
        <div
            style="visibility: hidden"
            class="tooltip-wrapper-box"
            :position="position"
        >
            <pre>{{ helpText }}</pre>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Tooltip',
    props: {
        helpText: {
            type: String,
            default: () => ''
        },
        position: {
            type: String,
            default: () => ''
        }
    },

    methods: {
        showTooltip(that){
            const elem = that.$el.getElementsByClassName('tooltip-wrapper-box')[0];
            if (!elem) return;
            
            elem.style.visibility = 'visible';
            elem.style.opacity = '1';
        },
        hideTooltip(that) {
            const elem = that.$el.getElementsByClassName('tooltip-wrapper-box')[0];
            if (!elem) return;
            
            elem.style.visibility = 'hidden';
            elem.style.opacity = '0';
        }
    }
};
</script>

<style>
.tooltip-wrapper {
    position: relative;
    display: inline;
}

.tooltip-wrapper-box {
    position: absolute;
    padding: 10px;
    background-color: #2A3042;
    z-index: 99999;
}

.tooltip-wrapper-box[position="bottom"] {
    top: 23px;
    left: 50%;
    transform: translateX(-50%);
}

.tooltip-wrapper-box[position="right"] {
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
}

.tooltip-wrapper-box:before {
    content: '';
    padding: 5px;
    background-color: #2A3042;
    position: absolute;
}

.tooltip-wrapper-box[position="bottom"]:before {
    top: -4px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
}

.tooltip-wrapper-box[position="right"]:before {
    top: 50%;
    left: -4px;
    transform: translateY(-50%) rotate(45deg);
}

.tooltip-wrapper-box {
    transition: visibility .2s ease, opacity .2s ease;
}

.tooltip-wrapper-box pre {
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
    color: #f8f8fb;
}
</style>