<template>
    <overlayed-card v-if="showForm">
        <template #content>
            <loading-layer :is-loading="buffer.isLoading" />

            <div class="card-body">
                <div class="form-header">
                    <p class="h6 font-weight-bold">
                        {{ __('FACILITIES.DISABLED_TITLE') }}
                    </p>

                    <button
                        class="btn-wide btn-secondary ml-auto"
                        @click="closeButtonHandler"
                    >
                        {{ __('BUTTONS.EXIT') }}
                    </button>
                </div>

                <div
                    v-if="buffer.successEnable"
                    class="alert alert-success"
                    role="alert"
                >
                    Facility successfully enabled.
                </div>
                <div
                    v-if="buffer.error != ''"
                    class="alert alert-success"
                    role="alert"
                >
                    {{ buffer.error }}
                </div>
                <table
                    :loading="buffer.isLoading"
                    class="table"
                >
                    <thead class="thead-light">
                        <tr>
                            <th scope="col">
                                {{ __('LABELS.FACILITY_NAME') }}
                            </th>
                            <th
                                scope="col"
                                class="xs-hide sm-hide"
                            >
                                {{ __('LABELS.COUNTRY') }}
                            </th>
                            <th
                                scope="col"
                                class="xs-hide sm-hide md-hide"
                            >
                                {{ __('LABELS.REGION') }}
                            </th>
                            <th
                                scope="col"
                                class="xs-hide sm-hide md-hide"
                            >
                                {{ __('LABELS.ACTIVITY') }}
                            </th>
                            <th
                                scope="col"
                                class="xs-hide"
                            >
                                {{ __('LABELS.SPECIES') }}
                            </th>
                            <th
                                scope="col"
                                class="xs-hide"
                            >
                                {{ __('LABELS.CATEGORY') }}
                            </th>
                            <th
                                scope="col"
                                class="text-center"
                            >
                                {{ __('LABELS.ACTIONS') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="facility in facilities"
                            :key="facility.name"
                        >
                            <td>{{ facility.name }}</td>
                            <td
                                class="xs-hide sm-hide"
                            >
                                {{ getCountryName(facility.countryId) }}
                            </td>
                            <td
                                class="xs-hide sm-hide md-hide"
                            >
                                {{ facility.region }}
                            </td>
                            <td
                                class="xs-hide sm-hide md-hide"
                            >
                                {{ getActivitiesString(facility.activities) }}
                            </td>
                            <td
                                class="xs-hide"
                            >
                                {{ getSpeciesString(facility.activities) }}
                            </td>
                            <td
                                class="xs-hide"
                            >
                                {{ getCategoryName(facility.categoryId) }}
                            </td>
                            <td class="text-center">
                                <button
                                    class="icon-button"
                                    @click="() => enableFacility(facility)"
                                >
                                    <i class="fas fa-check" />
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div
                    v-if="facilities.length == 0"
                    class="alert alert-info"
                    role="alert"
                >
                    {{ __('FACILITIES.NO_DISABLED') }}
                </div>
            </div>
        </template>
    </overlayed-card>
</template>

<script>
import API from '../utils/api-utils.js';
import __ from '../utils/translator.js';
import { getCategoryName, getCountryName, getActivitiesString, getSpeciesString } from '../db/db-utils.js';

import LoadingLayer from '../components/loading_layer.vue';
import OverlayedCard from '../components/overlayed-card.vue';

export default {
    name: 'CompanyFacilityDisabled',

    components: {
        LoadingLayer,
        OverlayedCard
    },

    props: {
        showForm: {
            default: () => false,
            type: Boolean
        }
    },

    emits: ['close-form', 'facility-enabled'],

    data() {
        return {
            buffer: {
                isLoading: true,
                successEnable: false,
                error: ''
            },

            facilities: []
        }
    },

    watch: {
        showForm(visible) {
            if (!visible)
                return;

            this.resetScreen();
            API.read.facility(false)
                .then(json => {
                    if (json.ok) {
                        this.facilities = json.data;
                        this.buffer.isLoading = false
                    } else {
                        this.buffer.loadingErrorMessage = 'Could not load facilities'
                    }
                })
                .catch(() => this.buffer.loadingErrorMessage = 'Network error, try again later.')
                .finally(() => this.buffer.isLoading = false);
        }
    },

    methods: {
        resetScreen() {
            this.buffer.successEnable = false;
            this.buffer.error = '';
            this.buffer.isLoading = true;
        },

        enableFacility(facility) {
            this.resetScreen();
            
            API.enable.facility(facility.name)
                .then(json => {
                    if (json.ok) {
                        this.successEnable(facility.name);
                        this.$emit('facility-enabled', facility);
                        this.buffer.successEnable = true;
                    } else if (json.error.message) {
                        this.buffer.error(`Error: ${json.error.message}`);
                    } else {
                        this.buffer.error('Facility could not be enabled, try again later.');
                    }
                })
                .catch(() => {})
                .finally(() => this.buffer.isLoading = false);
        },

        successEnable(facility) {
            this.facilities = this.facilities.filter(fac => fac.name != facility);
        },

        closeButtonHandler(ev) {
            ev.preventDefault();
            ev.stopPropagation();
            this.$emit('close-form');
        },

        getCategoryName,
        getCountryName,
        getActivitiesString,
        getSpeciesString,
        __ //translator
    }
};
</script>