<template>
    <overlayed-card v-if="showForm">
        <template #content>
            <loading-layer :is-loading="buffer.isLoading" />

            <form
                :loading="buffer.isLoading"
                @submit="checkForm"
            >
                <div class="form-header">
                    <p class="h6 font-weight-bold">
                        {{ __('FACILITIES.NEW_TITLE') }}
                    </p>
                    <button
                        class="btn-wide btn-secondary ml-auto"
                        @click="closeButtonHandler"
                    >
                        {{ __('BUTTONS.EXIT') }}
                    </button>
                    <input
                        class="btn-wide btn-primary ml-2"
                        type="submit"
                        :value="buffer.isUpdating ? 'Update' : 'Add'"
                    >
                </div>
                <div
                    v-if="buffer.updateErrorMessage != ''"
                    class="alert alert-danger"
                    role="alert"
                >
                    {{ buffer.updateErrorMessage }}
                </div>
                <div
                    v-if="buffer.infoMessage != ''"
                    class="alert alert-info"
                    role="alert"
                >
                    {{ buffer.infoMessage }}
                </div>
                <div class="row mt-5">
                    <!-- BUROCRACY -->
                    <div class="col">
                        <div
                            v-if="!buffer.isUpdating"
                            class="alert alert-warning"
                            role="alert"
                        >
                            <i class="fas fa-exclamation-triangle" />
                            {{ __('FACILITIES.NEW_WARNING') }}
                        </div>
                        <div
                            v-else
                            class="alert alert-info"
                            role="alert"
                        >
                            <i class="fas fa-info-circle" />
                            {{ __('FACILITIES.EDIT_WARNING') }}
                        </div>
                        <div class="form-group row">
                            <label
                                for="facility-name"
                                class="col-sm col-form-label"
                            >
                                {{ __('LABELS.FACILITY_NAME') }} <tooltip
                                    v-once
                                    :help-text="tooltips.facilityName"
                                    position="right"
                                />
                            </label>
                            <div class="col-sm-8">
                                <input
                                    v-if="buffer.isUpdating"
                                    id="facility-name"
                                    v-model.trim="formData.name"
                                    type="text"
                                    class="form-control"
                                    readonly
                                >
                                <input
                                    v-else
                                    id="facility-name"
                                    v-model.trim="formData.name"
                                    type="text"
                                    :class="`form-control ${buffer.formError.nameErrorMessage != '' ? 'is-invalid' : ''}`"
                                    placeholder="The name of the facility"
                                    :maxlength="buffer.Constants.MAX_FACILITYNAME_LENGTH"
                                    :minlength="buffer.Constants.MIN_FACILITYNAME_LENGTH"
                                >
                                <div
                                    v-if="buffer.formError.nameErrorMessage != ''"
                                    class="invalid-feedback"
                                >
                                    {{ buffer.formError.nameErrorMessage }}
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label
                                for="address"
                                class="col-sm col-form-label"
                            >{{ __('LABELS.ADDRESS') }}</label>
                            <div class="col-sm-8">
                                <input
                                    v-if="buffer.isUpdating"
                                    id="address"
                                    v-model.trim="formData.address"
                                    type="text"
                                    class="form-control"
                                    readonly
                                >
                                <input
                                    v-else
                                    id="address"
                                    v-model.trim="formData.address"
                                    type="text"
                                    :maxlength="buffer.Constants.MAX_ADDRESS_LENGTH"
                                    :minlength="buffer.Constants.MIN_ADDRESS_LENGTH"
                                    :class="`form-control ${buffer.formError.addressErrorMessage != '' ? 'is-invalid' : ''}`"
                                    placeholder="Address of the facility"
                                >
                                <div
                                    v-if="buffer.formError.addressErrorMessage != ''"
                                    class="invalid-feedback"
                                >
                                    {{ buffer.formError.addressErrorMessage }}
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label
                                for="country"
                                class="col-sm col-form-label"
                            >
                                {{ __('LABELS.COUNTRY') }}
                            </label>
                            <div class="col-sm-8">
                                <input
                                    v-if="buffer.isUpdating"
                                    id="country"
                                    :value="getCountryName(formData.countryId)"
                                    type="text"
                                    :class="`form-control ${buffer.formError.countryIdErrorMessage != '' ? 'is-invalid' : ''}`"
                                    readonly
                                >
                                <select
                                    v-else
                                    id="country"
                                    v-model.number="formData.countryId"
                                    :class="`form-control ${buffer.formError.countryIdErrorMessage != '' ? 'is-invalid' : ''}`"
                                >
                                    <option
                                        v-if="formData.countryId == -1"
                                        value="-1"
                                        hidden
                                        selected
                                    >
                                        {{ __('LABELS.SELECT_COUNTRY') }}
                                    </option>
                                    <option
                                        v-for="country in buffer.countries"
                                        :key="country.id"
                                        :value="country.id"
                                    >
                                        {{ country.name }}
                                    </option>
                                </select>
                                <div
                                    v-if="buffer.formError.countryIdErrorMessage != ''"
                                    class="invalid-feedback"
                                >
                                    {{ buffer.formError.countryIdErrorMessage }}
                                </div>
                            </div>
                        </div>
                        
                        <div class="form-group row">
                            <label
                                for="zip"
                                class="col-sm col-form-label"
                            >
                                {{ __('LABELS.ZIP') }}
                            </label>
                            <div class="col-sm-8">
                                <input
                                    v-if="buffer.isUpdating"
                                    id="zip"
                                    v-model.trim="formData.zipCode"
                                    type="text"
                                    class="form-control"
                                    readonly
                                >
                                <input
                                    v-else
                                    id="zip"
                                    v-model.trim="formData.zipCode"
                                    type="text"
                                    :maxlength="buffer.Constants.MAX_ZIP_LENGTH"
                                    :minlength="buffer.Constants.MIN_ZIP_LENGTH"
                                    :class="`form-control ${buffer.formError.zipCodeErrorMessage != '' ? 'is-invalid' : ''}`"
                                    placeholder="ZIP Code"
                                >
                                <div
                                    v-if="buffer.formError.zipCodeErrorMessage != ''"
                                    class="invalid-feedback"
                                >
                                    {{ buffer.formError.zipCodeErrorMessage }}
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label
                                for="region"
                                class="col-sm col-form-label"
                            >{{ __('LABELS.REGION') }}</label>
                            <div class="col-sm-8">
                                <input
                                    v-if="buffer.isUpdating"
                                    id="region"
                                    v-model.trim="formData.region"
                                    type="text"
                                    class="form-control"
                                    readonly
                                >
                                <input
                                    v-else
                                    id="region"
                                    v-model.trim="formData.region"
                                    type="text"
                                    :maxlength="buffer.Constants.MAX_REGION_LENGTH"
                                    :minlength="buffer.Constants.MIN_REGION_LENGTH"
                                    :class="`form-control ${buffer.formError.regionErrorMessage != '' ? 'is-invalid' : ''}`"
                                    placeholder="Region"
                                >
                                <div
                                    v-if="buffer.formError.regionErrorMessage != ''"
                                    class="invalid-feedback"
                                >
                                    {{ buffer.formError.regionErrorMessage }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- ACTIVITIES -->
                    <div class="col">
                        <div class="form-row">
                            <div class="form-group col-md">
                                <label for="activity">
                                    Activity <tooltip
                                        v-once
                                        :help-text="tooltips.activity"
                                        position="bottom"
                                    />
                                </label>
                                <select
                                    id="activity"
                                    v-model.number="buffer.formActivity.current.activityId"
                                    :class="`form-control ${buffer.formError.activitiesErrorMessage != '' ? 'is-invalid' : ''}`"
                                >
                                    <option
                                        value="-1"
                                        hidden
                                        selected
                                    >
                                        {{ __('LABELS.SELECT_ACTIVITY') }}
                                    </option>
                                    <option
                                        v-for="activity in buffer.activities"
                                        :key="activity.id"
                                        :value="activity.id"
                                    >
                                        {{ activity.name }}
                                    </option>
                                </select>
                                <div
                                    v-if="buffer.formError.activitiesErrorMessage != ''"
                                    class="invalid-feedback"
                                >
                                    {{ buffer.formError.activitiesErrorMessage }}
                                </div>
                            </div>

                            <div class="form-group col-md">
                                <label for="species">
                                    {{ __('LABELS.SPECIES') }} <tooltip
                                        v-once
                                        :help-text="tooltips.species"
                                        position="bottom"
                                    />
                                </label>
                                <div class="d-flex">
                                    <select
                                        id="species"
                                        v-model.number="buffer.formActivity.current.specieId"
                                        :class="`form-control ${buffer.formError.activitiesErrorMessage != '' ? 'is-invalid' : ''}`"
                                    >
                                        <option
                                            value="-1"
                                            hidden
                                            selected
                                        >
                                            {{ __('LABELS.SELECT_SPECIE') }}
                                        </option>
                                        <option
                                            v-for="specie in buffer.species"
                                            :key="specie.id"
                                            :value="specie.id"
                                        >
                                            {{ specie.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <button
                            class="btn btn-primary"
                            @click="addActivity"
                        >
                            {{ __('BUTTONS.ADD') }}
                        </button>
                        <table class="table mt-3">
                            <thead class="thead-light">
                                <tr class="text-center">
                                    <th>{{ __('LABELS.ACTIVITY') }}</th>
                                    <th>{{ __('LABELS.SPECIE') }}</th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="activity in buffer.formActivity.stored"
                                    :key="activity.key"
                                    class="text-center"
                                >
                                    <td>{{ getActivityName(activity.activityId) }}</td>
                                    <td>{{ getSpecieName(activity.specieId) }}</td>
                                    <td>
                                        <button
                                            :value="activity.key"
                                            class="icon-button"
                                            @click="deleteActivity($event, activity.key)"
                                        >
                                            <i class="fas fa-trash" />
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div
                            v-if="buffer.formActivity.stored.length == 0"
                            class="alert alert-info"
                            role="alert"
                        >
                            {{ __('FACILITIES.NO_ACTIVITIES') }}
                        </div>
                    </div>
                </div>
            </form>
        </template>
    </overlayed-card>
</template>

<script>
import Tooltip from '../components/tooltip.vue';
import TooltipStrings from '../lang/tooltips.json';
import LoadingLayer from '../components/loading_layer.vue';
import OverlayedCard from '../components/overlayed-card.vue';

import { getSpecieName, getActivityName, getCountryName } from '../db/db-utils.js';

import Constants from '../Constants.js';
import { equalObjects } from '../utils/form-utils.js';
import API from '../utils/api-utils.js';
import __ from '../utils/translator.js';


export default {
    components: {
        Tooltip,
        LoadingLayer,
        OverlayedCard
    },


    inject: ['GLOBAL_ACTIVITIES', 'GLOBAL_COUNTRIES', 'GLOBAL_SPECIES'],

    props: {
        facilityToModify: {
            default: () => {},
            type: Object
        },

        facilities: {
            default: () => {},
            type: Array
        },

        showForm: {
            default: () => false,
            type: Boolean
        },
    },

    emits: ['success-create', 'success-update', 'close-form'],

    data() {
        return {
            tooltips: {
                facilityName: TooltipStrings.eng.facilityName,
                activity: TooltipStrings.eng.activity,
                species: TooltipStrings.eng.species
            },

            buffer: {
                Constants,
                isLoading: false,
                isUpdating: false,

                countries: this.GLOBAL_COUNTRIES,
                activities: this.GLOBAL_ACTIVITIES,
                species: this.GLOBAL_SPECIES,

                oldFacilityName: '',
                formActivity: {
                    current: {
                        activityId: -1,
                        specieId: -1
                    },
                    stored: [] //[{}] array of 'current' objects, conains a 'key' property
                },

                formError: {
                    nameErrorMessage: '',
                    addressErrorMessage: '',
                    countryIdErrorMessage: '',
                    zipCodeErrorMessage: '',
                    regionErrorMessage: '',
                    activitiesErrorMessage: ''
                },
                originalData: {
                    name: '',
                    address: '',
                    countryId: -1,
                    zipCode: '',
                    region: '',
                    activities: []
                },

                updateErrorMessage: '',
                infoMessage: ''
            },

            formData: {
                name: '',
                address: '',
                countryId: -1,
                zipCode: '',
                region: '',
                categoryId: -1,
                activities: []
            }
        }
    },

    watch: {
        facilityToModify() {
            //Check if facility to modify is null, in this case, the fields are emptied
            if (this.facilityToModify == null) {
                this.resetForm();
                this.buffer.isUpdating = false;
            } else {
                //Copy facilityToModify into form data and buffer
                this.formData = { ...this.facilityToModify };
                this.buffer.originalData = { ...this.facilityToModify };
                this.buffer.formActivity.stored = this.formData.activities.map(obj => {
                    return {
                        key: `${obj.activityId}${obj.specieId}`,
                        activityId: obj.activityId,
                        specieId: obj.specieId
                    };
                });
                this.buffer.oldFacilityName = this.formData.name;
                this.buffer.isUpdating = true;
            }
        }
    },

    methods: {
        checkForm(ev) {
            ev.preventDefault();
            this.resetErrors();

            let error = false;
            //Facility name
            if (this.formData.name.length > Constants.MAX_FACILITYNAME_LENGTH) {
                this.buffer.formError.nameErrorMessage = `Max facility name length is ${Constants.MAX_FACILITYNAME_LENGTH}.`;
                error = true;
            } else if (this.formData.name.length < Constants.MIN_FACILITYNAME_LENGTH) {
                this.buffer.formError.nameErrorMessage = `Minimum facility name length is ${Constants.MIN_FACILITYNAME_LENGTH}.`;
                error = true;
            }


            //ADDRESS
            if (this.formData.address.length > Constants.MAX_ADDRESS_LENGTH) {
                this.buffer.formError.addressErrorMessage = `Max address length is ${Constants.MAX_ADDRESS_LENGTH}`;
                error = true;
            } else if (this.formData.address.length < Constants.MIN_ADDRESS_LENGTH) {
                this.buffer.formError.addressErrorMessage = `Minimum address length is ${Constants.MIN_ADDRESS_LENGTH}`;
                error = true;
            }


            //COUNTRY ID
            if (this.formData.countryId == -1 ||
                this.formData.countryId > Constants.MAX_COUNTRYID_VALUE ||
                this.formData.countryId < Constants.MIN_COUNTRYID_VALUE) {
                    this.buffer.formError.countryIdErrorMessage = `Please, select a country.`;
                    error = true;
            }


            //ZIP CODE
            if (this.formData.zipCode.length > Constants.MAX_ZIP_LENGTH) {
                this.buffer.formError.zipCodeErrorMessage = `Max zip code length is ${Constants.MAX_ZIP_LENGTH}.`;
                error = true;
            } else if (this.formData.zipCode.length < Constants.MIN_ZIP_LENGTH) {
                this.buffer.formError.zipCodeErrorMessage = `Minimum zip code length is ${Constants.MIN_ZIP_LENGTH}.`;
                error = true;
            }


            //REGION
            if (this.formData.region.length > Constants.MAX_REGION_LENGTH) {
                this.buffer.formError.regionErrorMessage = `Max region length is ${Constants.MAX_REGION_LENGTH}.`;
                error = true;
            } else if (this.formData.region.length < Constants.MIN_REGION_LENGTH) {
                this.buffer.formError.regionErrorMessage = `Minimum region length is ${Constants.MIN_REGION_LENGTH}.`;
                error = true;
            }

            // Don't screw me with structured programming
            // Small piece of code easily understandable
            // Checks if all the items of the array has the necessary information and is coherent
            // Olso check that does not contain any duplicated item in the array
            const stored = this.buffer.formActivity.stored; //reference
            const keys = [];
            this.formData.activities = []; //reset activities in form data
            let errorInActivities = false;
            for (let i = 0; i < stored.length; i++) {
                const comb = stored[i]; //reference
                //Undefined
                if (comb.activityId == undefined || comb.specieId == undefined) {
                    errorInActivities = true;
                    break;
                }
                //Numeric range values
                if (comb.activityId > Constants.MAX_ACTIVITYID_VALUE ||
                    comb.activityId < Constants.MIN_ACTIVITYID_VALUE ||
                    comb.specieId > Constants.MAX_SPECIEID_VALUE ||
                    comb.specieId < Constants.MIN_SPECIEID_VALUE) {
                        errorInActivities = true;
                        break;
                }
                //Duplicated items
                const indx = `${comb.activityId}${comb.specieId}`;
                if (keys.indexOf(indx) != -1) {
                    errorInActivities = true;
                    break;
                }
                keys.push(indx);
                this.formData.activities.push({ //set activities in form data
                    activityId: comb.activityId,
                    specieId: comb.specieId
                });
            }
            //final check of activities 
            if (errorInActivities || stored.length == 0) {
                this.buffer.formError.activitiesErrorMessage = 'Please, select a value.';
                error = true;
            }

            //Facility name already exists ONLY IF IS NOT UPDATING
            if (!this.buffer.isUpdating || this.formData.name != this.buffer.originalData.name) {
                const facilityNames = this.facilities.map(f => f.name);
                if (facilityNames.indexOf(this.formData.name) != -1) {
                    this.buffer.formError.nameErrorMessage = 'Facility already exists';
                    error = true;
                }
            }

            if (!error && equalObjects(this.formData, this.buffer.originalData)) {
                this.buffer.infoMessage = 'Nothing to update.';
                error = true; // nothing to update
            }

            if (error)
                return;

            this.buffer.isLoading = true;
            this.formData.categoryId = Constants.MIN_CATEGORYID_VALUE;
            if (this.buffer.isUpdating) {
                //Updating
                API.update.facility(this.buffer.oldFacilityName, this.formData)
                    .then(json => {
                        if (json.ok) {
                            this.exitForm('success-update', this.buffer.oldFacilityName, this.formData);
                        } else if (json.error.message) {
                            this.buffer.updateErrorMessage = `Error: ${json.error.message}.`;
                        } else {
                            this.buffer.updateErrorMessage = 'Facility could not be updated';
                        }
                    })
                    .catch(() => this.buffer.updateErrorMessage = 'Network error, try again later')
                    .finally(() => this.buffer.isLoading = false);
            } else {
                //Creating
                API.create.facility(this.formData)
                    .then(json => {
                        if (json.ok) {
                            this.exitForm('success-create', this.formData);
                        } else if (json.error.message) {
                            this.buffer.updateErrorMessage = `Error: ${json.error.message}.`;
                        } else {
                            this.buffer.updateErrorMessage = 'Facility could not be updated';
                        }
                    })
                    .catch(() => this.buffer.updateErrorMessage = 'Network error, try again later')
                    .finally(() => this.buffer.isLoading = false);
            }
        },

        closeButtonHandler(ev) { //handling close form button event
            ev.preventDefault();
            this.exitForm('close-form');
        },

        exitForm(event, ...args) { //Actually closes the form
            this.resetErrors();
            this.resetForm();
            this.$emit(event, ...args);
        },

        addActivity(ev) {
            ev.preventDefault();
            const current = this.buffer.formActivity.current;   //reference
            const stored = this.buffer.formActivity.stored;     //reference

            if (current.activityId == -1 || current.specieId == -1)
                return;

            const filter = stored.filter(obj => (obj.activityId == current.activityId) && (obj.specieId == current.specieId) );

            if (filter.length == 0) {
                //id = concat of activityId and specieId
                stored.push({key: `${current.activityId}${current.specieId}` , activityId: current.activityId, specieId: current.specieId}); //copyvalues 
                current.specieId = -1;
                current.activityId = -1;
                this.buffer.formError.activities = false;
            }
        },

        deleteActivity(ev, key) {
            ev.preventDefault();
            this.buffer.formActivity.stored = this.buffer.formActivity.stored.filter(obj => obj.key != key);
        },

        resetErrors() {
            this.buffer.formError = {
                nameErrorMessage: '',
                addressErrorMessage: '',
                countryIdErrorMessage: '',
                zipCodeErrorMessage: '',
                regionErrorMessage: '',
                activitiesErrorMessage: ''
            };
            this.buffer.updateErrorMessage = '';
            this.buffer.infoMessage = '';
        },

        resetForm() {
            this.formData = {
                name: '',
                address: '',
                countryId: -1,
                zipCode: '',
                region: '',
                categoryId: -1,
                activities: []
            };
            this.buffer.formActivity = {
                current: {
                    activityId: -1,
                    specieId: -1
                },
                stored: []
            };
        },

        getSpecieName,
        getActivityName,
        getCountryName,
        __ //translator
    }
};
</script>