<template>
    <loading-layer :is-loading="buffer.isLoading" />

    <div
        :loading="buffer.isLoading"
        class="form-header"
    >
        <p class="h6 font-weight-bold">
            {{ __('MENUBAR.FACILITIES') }}
        </p>

        <button
            class="btn-wide btn-secondary ml-auto mx-3"
            @click="() => buffer.showDisabledForm = true"
        >
            {{ __('BUTTONS.DISABLED_FACILITIES') }}
        </button>

        <span
            v-if="facilities.length >= Constants.MAX_FACILITIES"
            class="mr-3 ml-auto my-auto badge badge-pill badge-danger"
        >{{ __('FACILITIES.MAX_FACILITIES') }} ({{ Constants.MAX_FACILITIES }})!!</span>
        <button
            :class="`btn-wide btn-primary ${facilities.length >= Constants.MAX_FACILITIES ? 'disabled' : ''}`"
            @click="if (facilities.length < Constants.MAX_FACILITIES) showForm();"
        >
            {{ __('BUTTONS.NEW_FACILITY') }}
        </button>
    </div>

    <company-facility-form
        :show-form="buffer.showForm"
        :facility-to-modify="buffer.facilityToModify"
        :facilities="facilities"
        @success-update="successUpdate"
        @success-create="successCreate"
        @close-form="closeForm()"
    />

    <company-facility-disabled
        :show-form="buffer.showDisabledForm"
        @close-form="() => buffer.showDisabledForm = false"
        @facility-enabled="(fac) => facilities.push(fac)"
    />

    <div
        v-if="buffer.errorMessage != ''"
        class="alert alert-danger"
        role="alert"
    >
        {{ buffer.errorMessage }}
    </div>
    <div
        v-if="buffer.successMessage != ''"
        class="alert alert-success"
        role="alert"
    >
        {{ buffer.successMessage }}
    </div>
    <table
        :loading="buffer.isLoading"
        class="table"
    >
        <thead class="thead-light">
            <tr>
                <th scope="col">
                    {{ __('LABELS.FACILITY_NAME') }}
                </th>
                <th
                    scope="col"
                    class="xs-hide sm-hide"
                >
                    {{ __('LABELS.COUNTRY') }}
                </th>
                <th
                    scope="col"
                    class="xs-hide sm-hide"
                >
                    {{ __('LABELS.REGION') }}
                </th>
                <th
                    scope="col"
                    class="xs-hide sm-hide"
                >
                    {{ __('LABELS.ACTIVITIES') }}
                </th>
                <th
                    scope="col"
                    class="xs-hide"
                >
                    {{ __('LABELS.SPECIES') }}
                </th>
                <th
                    scope="col"
                    class="xs-hide"
                >
                    {{ __('LABELS.CATEGORY') }}
                </th>
                <th
                    scope="col"
                    class="text-center"
                >
                    {{ __('LABELS.ACTIONS') }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr
                v-for="facility in facilities"
                :key="facility.name"
            >
                <td>{{ facility.name }}</td>
                <td class="xs-hide sm-hide">
                    {{ getCountryName(facility.countryId) }}
                </td>
                <td class="xs-hide sm-hide">
                    {{ facility.region }}
                </td>
                <td class="xs-hide sm-hide">
                    {{ getActivitiesString(facility.activities) }}
                </td>
                <td class="xs-hide">
                    {{ getSpeciesString(facility.activities) }}
                </td>
                <td class="xs-hide">
                    {{ getCategoryName(facility.categoryId) }}
                </td>
                <td class="text-center">
                    <button
                        class="icon-button"
                        @click="editFacility(facility)"
                    >
                        <i class="fas fa-eye" />
                    </button>
                    <button
                        class="icon-button"
                        @click="deleteFacility(facility.name)"
                    >
                        <i class="fas fa-times" />
                    </button>
                </td>
            </tr>
        </tbody>
    </table>

    <div
        v-if="facilities.length == 0 && buffer.loadingErrorMessage == ''"
        class="alert alert-info"
        role="alert"
    >
        {{ __('FACILITIES.NO_FACILITIES') }}
    </div>
    <div
        v-if="buffer.loadingErrorMessage != ''"
        class="alert alert-danger"
        role="alert"
    >
        {{ buffer.loadingErrorMessage }}
    </div>
</template>

<script>
import CompanyFacilityForm from '../../components/company_facilities-form.vue';
import CompanyFacilityDisabled from '../../components/company_facilities-disabled.vue';
import LoadingLayer from '../../components/loading_layer.vue';

import { getCountryName, getActivitiesString, getSpeciesString, getCategoryName  } from '../../db/db-utils.js';

import API from '../../utils/api-utils.js';
import __ from '../../utils/translator.js';
import Constants from '../../Constants';

export default {

    components: {
        CompanyFacilityForm,
        CompanyFacilityDisabled,
        LoadingLayer
    },

    inject: ['GLOBAL_ACTIVITIES', 'GLOBAL_COUNTRIES', 'GLOBAL_SPECIES'],

    data() {
        return {
            buffer: {
                loadingErrorMessage: '',
                isLoading: true,
                showForm: false,
                showDisabledForm: false,

                facilityToModify: null,
                successMessage: '',
                errorMessage: ''
            },

            facilities: [],

            Constants
        };
    },
    
    beforeCreate() {
        API.read.facility()
            .then(response => {
                if (response.ok) {
                    this.facilities = response.data;
                    this.buffer.isLoading = false
                } else {
                    this.buffer.loadingErrorMessage = response.error.message;
                }
            })
            .catch(() => this.buffer.loadingErrorMessage = 'Network error, try again later.')
            .finally(() => this.buffer.isLoading = false);
    },

    methods: {
        resetMessages() {
            this.buffer.successMessage = '';
            this.buffer.errorMessage = '';
        },

        showForm() {
            this.resetMessages();
            this.buffer.showForm = true;
        },

        editFacility(facility) {
            this.showForm();
            this.buffer.facilityToModify = facility;
        },

        deleteFacility(facilityName) {
            if (!confirm(`Are you sure you want to DISABLE facility "${facilityName}"?`))
                return;

            if (facilityName > Constants.MAX_FACILITYNAME_LENGTH)
                return;

            this.resetMessages();
            this.buffer.isLoading = true;
            API.delete.facility(facilityName)
                .then(response => {
                    if (response.ok) {
                        this.facilities = this.facilities.filter(f => f.name != facilityName);
                        this.buffer.successMessage = `"${facilityName}" successfully disabled`;
                        this.buffer.isLoading = false;
                    } else {
                        this.buffer.errorMessage = response.error.message;
                    }
                })
                .catch(() => this.buffer.errorMessage = 'Network error, try again later.')
                .finally(() => this.buffer.isLoading = false);
        },

        closeForm() {
            this.buffer.showForm = false;
            this.buffer.facilityToModify = null;
        },

        successUpdate(oldName, newData) {
            this.facilities = this.facilities.map(facility => {
                if (facility.name != oldName)
                    return facility;

                return newData;
            });
            this.closeForm();
            this.buffer.successMessage = `"${oldName}" successfully updated`;
        },

        successCreate(newData) {
            this.facilities.push(newData);
            this.closeForm();
            this.buffer.successMessage = `"${newData.name}" successfully created`;
        },

        getCountryName,
        getActivitiesString,
        getSpeciesString,
        getCategoryName,
        __ //translator
    }
};
</script>